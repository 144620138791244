import React from 'react';
import { useTheme } from 'styled-components';

import { animationContainer, fadeInLeft, fadeInRight } from '../../animations/variants';
import image from '../../assets/images/employee-bike-hero.png';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';

import {
	ScEmployeeCyclingHeroContainer,
	ScEmployeeCyclingHeroLeft,
	ScEmployeeCyclingHeroRight,
	ScEmployeeCyclingHeroWrapper,
	ScHeroContainer,
} from './styled';

export const EmployeeCyclingHero = () => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1024px)`);
	const { ref, controls } = useContentAnimation();

	return (
		<ScEmployeeCyclingHeroContainer>
			<ScTop disableHeight>
				<ScHeroContainer
					ref={ref}
					initial="hidden"
					animate={controls}
					variants={animationContainer}
					className={'cycling-page'}
				>
					<ScContainer>
						<ScEmployeeCyclingHeroWrapper>
							<ScEmployeeCyclingHeroLeft variants={fadeInRight}>
								<img src={image} alt="bread" />
							</ScEmployeeCyclingHeroLeft>
							<ScEmployeeCyclingHeroRight variants={fadeInLeft}>
								<h1>Save with Gogeta Cycle to Work</h1>
								<ScParagraph color={theme.colors.darkText} centered={!isDesktop}>
                  Gogeta is the only cycle to work scheme endorsed by bike retailers, so you get the best deals.
								</ScParagraph>
							</ScEmployeeCyclingHeroRight>
						</ScEmployeeCyclingHeroWrapper>
					</ScContainer>
				</ScHeroContainer>
			</ScTop>
		</ScEmployeeCyclingHeroContainer>
	);
};
